require('./bootstrap');
require('justifiedGallery/dist/js/jquery.justifiedGallery');
require('jquery-match-height/dist/jquery.matchHeight');
require('bootstrap-fileinput/js/fileinput');

$(".gallery").justifiedGallery({
    rowHeight: 175,
    // lastRow: 'justify',
    margins: 5,
    border: 0
});

$(".gallery-objects").justifiedGallery({
    rowHeight: 175,
    margins: 5,
    border: 0
});

$(function() {
    $(".news .item .image").matchHeight();
    $(".news .item").matchHeight();
    $(".objects .item").matchHeight();

    // $(".file-input").fileinput({
    //     showUpload : false,
    //     showPreview: false
    // }).on('fileclear', function(event) {
    //     let id = '#_' + event.target.id
    //     $(id).val('');
    // });
});
